<template>
  <v-data-table
    :headers="headers"
    :items="banks"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              บัญชีธนาคาร
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >

                    <v-img
                      v-if="editedItem.bank_img"
                      :src="editedItem.bank_img" height="150"></v-img>

                    <v-file-input
                      v-model="editedItem.img"
                      show-size
                      accept="image/png, image/jpeg, image/bmp"
                      label="รูป"></v-file-input>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.BankName"
                      label="ชื่อธนาคาร"
                    ></v-text-field>

                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.BankName.$silentErrors"
                      v-if="v$.editedItem.BankName.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-text-field
                      v-model="editedItem.brand"
                      label="สาขา"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-text-field
                      v-model="editedItem.account_name"
                      label="ชื่อบัญชี"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-text-field
                      v-model="editedItem.account_type"
                      label="ประเภทบัญชี"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-text-field
                      v-model="editedItem.account_number"
                      label="เลขบัญชี"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--        <v-dialog-->
        <!--          v-model="dialogDelete"-->
        <!--          max-width="600px"-->
        <!--        >-->
        <!--          <v-card>-->
        <!--            <v-card-title class="text-h5 mb-5">-->
        <!--              Are you sure you want to delete this item?-->
        <!--            </v-card-title>-->
        <!--            <v-card-actions>-->
        <!--              <v-spacer></v-spacer>-->
        <!--              <v-btn-->
        <!--                color="error"-->
        <!--                outlined-->
        <!--                @click="closeDelete"-->
        <!--              >-->
        <!--                Cancel-->
        <!--              </v-btn>-->
        <!--              <v-btn-->
        <!--                color="primary"-->
        <!--                @click="deleteItemConfirm"-->
        <!--              >-->
        <!--                OK-->
        <!--              </v-btn>-->
        <!--              <v-spacer></v-spacer>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-dialog>-->
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <!--      <v-icon-->
      <!--        small-->
      <!--        @click="deleteItem(item)"-->
      <!--      >-->
      <!--        {{ icons.mdiDelete }}-->
      <!--      </v-icon>-->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_bank from '../../../services/bank'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    banks: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'ชื่อธนาคาร', value: 'BankName'},
      {text: 'สาขา', value: 'brand'},
      {text: 'ชื่อบัญชี', value: 'account_name'},
      {text: 'ประเภทบัญชี', value: 'account_type'},
      {text: 'เลขบัญชี', value: 'account_number'},
      {text: 'สถานะ', value: 'BankStatus'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      BankName: '',
      brand: '',
      account_name: '',
      account_type: '',
      account_number: '',
      BankStatus: 'Y',
      img: null,
    },
    defaultItem: {
      BankName: '',
      brand: '',
      account_name: '',
      account_type: '',
      account_number: '',
      BankStatus: 'Y',
      img: null,
    },
  }),
  validations() {
    return {
      editedItem: {
        BankName: {required },
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม บัญชีธนาคาร' : 'แก้ไข บัญชีธนาคาร'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getBank()
      },
    },
    search : {
      handler(){
        this.getBank()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getBank()
  },

  methods: {

    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.banks.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    // deleteItem(item) {
    //   this.editedIndex = this.employees_types.indexOf(item)
    //   this.editedItem = { ...item }
    //   this.dialogDelete = true
    // },

    // deleteItemConfirm() {
    //   instance_employee_type.employeeTypeDestroy(this.editedItem).then(res => {
    //
    //     this.desserts.splice(this.editedIndex, 1)
    //   }).catch(err => {
    //
    //   })
    //   this.closeDelete()
    // },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_bank.bankUpdate(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
            this.getBank();
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_bank.bankStore(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
            this.getBank();
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getBank() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        status: '',
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_bank.list(data).then(res => {
        this.banks = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
        console.log(err)
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
